@import "styles/variables";

$themeColor: $base-main-color;

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear, 0% 100%, 0% 0%, color-stop(0.44, rgb(122, 153, 217)), color-stop(0.72, rgb(73, 125, 189)), color-stop(0.86, rgb(28, 58, 148)));
}

.showBtn {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.active {
  color: $themeColor !important;
}

.defaultDotActive span {
  background: #fff !important;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}