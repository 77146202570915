
.mainContainer {
  i {
    font-size: 14px;
    margin-right: 5px;
    color: #fff;
    width: 32px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    transition: all .5s;
    display: inline-block;
    background: #000;
    border: 1px solid transparent;
  }

  i:hover {
    border-radius: 50%;
    background: #4b4b4b;
  }
}