/*---------------------------------
* About Us
-----------------------------------*/
.fullScreen {
  min-height: 100vh;
  height: 100%;
}

.aboutContainer {
  position: relative;
  background: url("../../../public/images/about-bg.png");
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 60%);
  .aboutLeft{
    h2 {
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      margin: 0 0 15px;
    }
     h3 {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      margin: 0 0 25px;
    }

    p {
      width: 470px;
      max-width: 100%;
      font-size: 15px;
      color: #fff;
    }
    a {
      z-index: 1;
      position: relative;
    }
  }
}
