
.mainContainer {
  margin: 15px 0;
  i {
    color: #3f3f3f;
    font-size: 20px;
    padding-right: 10px;
    width: 30px;
  }
  span a {
    color: #000;
  }
}