
/*---------------------------------
* 01. Loading
-----------------------------------*/
#loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 999999;
}

.loadCircle:after,
.loadCircle:before{
  content: '';
  background: #fff;
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  transition: all 1s;
}

.loadCircle:after{
  top: 0;
}

.loadCircle:before{
  bottom: 0;
}

.loadCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #e8e8e8;
  border-top: 4px solid #000;
  -webkit-animation: rotate 1.2s infinite linear;
  animation: rotate 1.2s infinite linear;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@-webkit-keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
