.sideMenuBtn {
  position: absolute;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  width: 35px;
  padding: 5px;
  margin-right: 1rem;
  margin-top: 1px;
  right: 20px;
  cursor: pointer;
}

.sideMenuBtn > span {
  height: 2px;
  width: 100%;
  display: block;
  background: #fff;
}

.sideMenuBtn > span:nth-child(2) {
  margin: 4px 0 4px auto;
  width: 75%;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.sideMenuBtn > span:nth-child(3) {
  margin-left: auto;
  width: 45%;
  -webkit-transition: 0.6s all;
  -moz-transition: 0.6s all;
  -o-transition: 0.6s all;
  transition: 0.6s all;
}

.sideMenuBtn:hover, .sideMenuBtn:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
  span:nth-child(2), span:nth-child(3) {
    width: 100%;
  }
}