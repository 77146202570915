
.overlayImg{
  position: absolute;
  width: 50%;
  height: 100%;
  background: url("../../../public/images/overlay.png");
  top: 0;
  opacity: .2;
}

@media screen and (max-width: 600px) {
  .overlayImg {
    width: 100%;
  }
}