
.titleContainer {
  position: absolute;
  top: 50%;
  width: 280px;
  left: -100px;
  p {
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .17em;
    text-align: center;
    transform: rotate(-90deg);
    position: relative;
    display: block;
  }

  p::before {
    content: "";
    width: 32px;
    height: 1px;
    background: #fff;
    position: absolute;
    top: 12px;
  }
  p::after {
    content: "";
    width: 32px;
    height: 1px;
    background: #fff;
    position: absolute;
    top: 12px;
  }

  .defaultAdjust::before {
    left: 50px;
  }
  .defaultAdjust::after {
    right: 50px;
  }

  .doAdjust::before {
    left: 70px;
  }
  .doAdjust::after {
    right: 75px;
  }
}

@media screen and (min-width: 601px) and (max-width: 951px) {
  .titleContainer {
    left: -96px;
  }
}

@media screen and (max-width: 600px) {
  .titleContainer {
    display: none;
  }
}