.contactContainer {
  position: relative;
  background: url("../../../public/images/contact-bg.jpg");
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 80%);

  .contactDetail {
    background: #fff;
    padding: 30px;
    border-radius: 5px;

    h4 {
      margin-bottom: 15px;
      color: #3c3c3c;
      font-weight: 700;
    }

    .contactText {
      padding-bottom: 10px;
      margin: 15px 0;
    }

    .socialIcons {
      p {
        font-weight: 600;
        font-size: 18px;
        padding-top: 10px;
        margin: 15px 0;
      }
    }
  }

  .copyRight {
    color: #fff;
  }
}